import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import * as VueGoogleMaps from "vue2-google-maps";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";

import VueNumber from "vue-number-animation";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VideoBackground from "vue-responsive-video-background-player";

library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("video-background", VideoBackground);

Vue.use(VueNumber);

Vue.config.productionTip = false;

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyB8aGiRiR9snY2frAlKvb0SJKKSPsUcIKM",
//     libraries: "places" // necessary for places input
//   }
// });

axios.defaults.timeout = 100000;

new Vue({
  router,
  store,
  vuetify,
  created() {
    AOS.init({ offset: 100, duration: 700, easing: "ease-out-quad", once: !0 });
    window.addEventListener("load", AOS.refresh);
  },
  render: h => h(App)
}).$mount("#app");
