import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import(/* webpackChunkName: "Home" */ "@/views/Home");
const Predictor = () =>
  import(/* webpackChunkName: "Predictor" */ "@/views/Predictor");
const Datasets = () =>
  import(/* webpackChunkName: "Datasets" */ "@/views/Datasets");
const Help = () => import(/* webpackChunkName: "Help" */ "@/views/Help");
const Team = () => import(/* webpackChunkName: "Team" */ "@/views/Team");
const Contact = () =>
  import(/* webpackChunkName: "Contact" */ "@/views/Contact");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/predictor",
    name: "Predictor",
    component: Predictor
  },
  {
    path: "/datasets",
    name: "Datasets",
    component: Datasets
  },
  {
    path: "/help",
    name: "Help",
    component: Help
  },
  {
    path: "/team",
    name: "Team",
    component: Team
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // Go to top after route changed
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
