<template>
  <v-app>
    <div
      class="page-container"
      v-bind:class="{ 'background-purple': background_colored }"
    >
      <div class="content-wrap">
        <nav class="shadow">
          <div>
            <div style="cursor: pointer;">
              <v-img
                contain
                @click="goToHome()"
                src="./assets/images/egfrnet_color.svg"
                class="img"
              />
            </div>
            <div>
              <ul class="desktop">
                <li @click="goToHome()" :class="{ active: homeActive }">
                  Home
                </li>
                <li
                  @click="goToPredictor()"
                  :class="{ active: predictorAcive }"
                >
                  Predictor
                </li>
                <li @click="goToHelp()" :class="{ active: helpActive }">
                  Help
                </li>
                <li @click="goToTeam()" :class="{ active: teamActive }">
                  Team
                </li>
                <li @click="goToContact()" :class="{ active: contactActive }">
                  Contact
                </li>
              </ul>
              <div class="mobile">
                <button @click="mobile_nav = true">
                  <font-awesome-icon :icon="['fa', 'bars']" />
                </button>
              </div>
            </div>
          </div>
        </nav>
        <v-navigation-drawer
          v-model="mobile_nav"
          right
          class="mobile-navbar"
          style="right: 0; left: auto;"
        >
          <div>
            <div>
              <v-img
                contain
                @click="goToHome()"
                src="./assets/images/egfrnet_color.svg"
                class="img"
              />
            </div>
            <ul>
              <li @click="goToHome()" :class="{ active: homeActive }">Home</li>
              <li @click="goToPredictor()" :class="{ active: predictorAcive }">
                Predictor
              </li>
              <li @click="goToHelp()" :class="{ active: helpActive }">Help</li>
              <li @click="goToTeam()" :class="{ active: teamActive }">Team</li>
              <li @click="goToContact()" :class="{ active: contactActive }">
                Contact
              </li>
            </ul>
            <div class="close">
              <button @click="mobile_nav = false">
                <font-awesome-icon :icon="['fas', 'times']" />
              </button>
            </div>
          </div>
        </v-navigation-drawer>
        <router-view
          v-bind:processing="processing"
          v-bind:end_process="end_process"
          v-bind:setActive="setActive"
        ></router-view>
        <footer>
          <div class="journal">
            <h2>Citation</h2>
            <div>
              EGFRNet: Spatial graph embedding and molecular descriptors<br />
              assisted bioactivity prediction of ligand
              molecules&nbsp;for&nbsp;epidermal<br />
              growth factor receptor on a cell line-based dataset
              <div>
                <a
                  href="https://doi.org/10.1101/2020.12.24.423424"
                  target="_blank"
                  rel="noopener noreferrer"
                  >DOI: 10.1101/2020.12.24.423424</a
                >
              </div>
            </div>
            <div>
              <a
                href="https://doi.org/10.1101/2020.12.24.423424"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button class="btn btn-secondary">
                  Go To Journal
                </button>
              </a>
            </div>
          </div>
          <div class="footer">
            Copyright @ 2020 Kamnoetvidya Science Academy &amp; Vidyasirimedhi
            Institute of Science and Technology
          </div>
        </footer>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      background_colored: false,
      mobile_nav: false,
      homeActive: true,
      predictorAcive: false,
      helpActive: false,
      teamActive: false,
      contactActive: false
    };
  },
  methods: {
    setActive(path) {
      this.homeActive = false;
      this.predictorAcive = false;
      this.helpActive = false;
      this.teamActive = false;
      this.contactActive = false;

      switch (path) {
        case "home":
          this.homeActive = true;
          break;
        case "predictor":
          this.predictorAcive = true;
          break;
        case "help":
          this.helpActive = true;
          break;
        case "team":
          this.teamActive = true;
          break;
        case "contact":
          this.contactActive = true;
          break;
        default:
          break;
      }
    },
    goToHome() {
      const path = "/";
      if (this.$route.path !== path) this.$router.push(path);
      this.setActive("home");
    },
    goToPredictor() {
      const path = "/predictor";
      if (this.$route.path !== path) this.$router.push(path);
      this.setActive("predictor");
    },
    // goToAlgorithm() {
    //   this.$router.push("/algorithm");
    // },
    // goToDatasets() {
    //   const path = "/datasets";
    //   if (this.$route.path !== path) this.$router.push(path);
    // },
    goToHelp() {
      const path = "/help";
      if (this.$route.path !== path) this.$router.push(path);
      this.setActive("help");
    },
    goToTeam() {
      const path = "/team";
      if (this.$route.path !== path) this.$router.push(path);
      this.setActive("team");
    },
    goToContact() {
      const path = "/contact";
      if (this.$route.path !== path) this.$router.push(path);
      this.setActive("contact");
    },
    processing() {
      this.background_colored = true;
    },
    end_process() {
      this.background_colored = false;
    }
  }
};
</script>

<style>
@import url("./assets/stylesheet/stylesheet.css");

nav {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.95);
  top: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 0px 0px 41px -14px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 41px -14px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 41px -14px rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

nav > div {
  padding: 10px 80px;
  display: grid;
  grid-template-columns: repeat(2, auto);
}

nav .img {
  width: 150px;
}

nav > div > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

nav > div > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

nav ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, auto);
}

nav li {
  font-family: "IBMPlexSans Bold";
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  color: #582e91;
  margin: 0 5px;
  cursor: pointer;
}

nav li:hover {
  background-color: #efe9fc;
}

nav .mobile {
  display: none;
}

.mobile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 1000000000;
}

.mobile-navbar > div > div > div:first-child {
  padding: 20px 40px;
}

.mobile-navbar .img {
  width: 80%;
  padding: 10px 20px;
}

.mobile-navbar ul {
  list-style: none;
  padding: 0px !important;
}

.mobile-navbar li {
  font-family: "IBMPlexSans Bold";
  padding: 15px 40px;
  border-bottom: 1px solid #f1f1f1;
  color: #582e91;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.active {
  background-color: #efe9fc;
  border: none;
}

.mobile-navbar li:hover {
  color: #8e67bb;
}

.mobile-navbar .close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 1.6em;
  color: #582e91;
}

.journal {
  background-color: #c9c9c9;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.journal > h2 {
  margin: 0px 60px;
}

.journal > div:nth-child(2) {
  font-family: "IBMPlexSans Medium";
  margin: 0px 60px;
  font-size: 0.9em;
  line-height: 1.4em;
}

.journal > div:nth-child(2) > div {
  margin-top: 10px;
}

.journal > div:nth-child(2) > div > a {
  font-family: "IBMPlexSans Medium";
  font-size: 0.9em;
  text-decoration: none;
  color: #000000;
}

.journal > div:nth-child(2) > div > a:hover {
  text-decoration: underline;
}

.journal button {
  font-size: 1em;
  font-family: "IBMPlexSans Bold";
  color: #000000;
}

.footer {
  font-family: "IBMPlexSans Light";
  background-color: #6c6c6c;
  color: #ffffff;
  text-align: center;
  padding: 30px;
}

@media screen and (max-width: 991px) {
  nav .desktop {
    display: none;
  }
  nav .mobile {
    font-size: 1.4em;
    display: block;
    color: #582e91;
  }
  nav > div {
    padding: 15px 30px;
  }
  .journal > h2 {
    margin: 0px 0px;
  }
  .footer {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 575px) {
  .journal {
    display: block;
    text-align: left;
    padding: 30px 40px;
  }
  .journal > h2 {
    margin: 0px 0px;
  }
  .journal > div:nth-child(2) {
    margin: 15px 0px;
  }
  .journal > div:last-child {
    text-align: left;
    padding: 5px 0px;
  }
  .journal button {
    font-size: 0.9em;
  }
}
</style>
